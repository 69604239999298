@font-face {
	font-family: 'universh-icon';
	src: url(../../fonts/univershicon/iconmind.eot);
	src: url(../../fonts/univershicon/iconmind.svg);
	src:url(../../fonts/univershicon/iconmind.ttf);
	src:url(../../fonts/univershicon/iconmind.woff);
	font-weight: normal;
	font-style: normal;
	
}

[class^="uni-"], [class*=" uni-"] {
	font-family: 'universh-icon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.uni-gear:before {
	content: "\e600";
}
.uni-gears:before {
	content: "\e601";
}
.uni-information:before {
	content: "\e602";
}
.uni-magnifi-glass-:before {
	content: "\e603";
}
.uni-magnifi-glass:before {
	content: "\e604";
}
.uni-magnifi-glass2:before {
	content: "\e605";
}
.uni-preview:before {
	content: "\e606";
}
.uni-pricing:before {
	content: "\e607";
}
.uni-repair:before {
	content: "\e608";
}
.uni-support:before {
	content: "\e609";
}
.uni-user:before {
	content: "\e60a";
}
.uni-equalizer:before {
	content: "\e60b";
}
.uni-microphone-2:before {
	content: "\e60c";
}
.uni-rock-androll:before {
	content: "\e60d";
}
.uni-sound-wave:before {
	content: "\e60e";
}
.uni-close-window:before {
	content: "\e60f";
}
.uni-network-window:before {
	content: "\e610";
}
.uni-settings-window:before {
	content: "\e611";
}
.uni-two-windows:before {
	content: "\e612";
}
.uni-upload-window:before {
	content: "\e613";
}
.uni-url-window:before {
	content: "\e614";
}
.uni-width-window:before {
	content: "\e615";
}
.uni-windows-2:before {
	content: "\e616";
}
.uni-drop:before {
	content: "\e617";
}
.uni-clapperboard-open:before {
	content: "\e618";
}
.uni-video-3:before {
	content: "\e619";
}
.uni-hand-touch2:before {
	content: "\e61a";
}
.uni-thumb:before {
	content: "\e61b";
}
.uni-clock:before {
	content: "\e61c";
}
.uni-watch:before {
	content: "\e61d";
}
.uni-normal-text:before {
	content: "\e61e";
}
.uni-text-box:before {
	content: "\e61f";
}
.uni-text-effect:before {
	content: "\e620";
}
.uni-archery-2:before {
	content: "\e621";
}
.uni-medal-3:before {
	content: "\e622";
}
.uni-skate-shoes:before {
	content: "\e623";
}
.uni-trophy:before {
	content: "\e624";
}
.uni-speach-bubbleasking:before {
	content: "\e625";
}
.uni-speach-bubbledialog:before {
	content: "\e626";
}
.uni-inifity:before {
	content: "\e627";
}
.uni-quotes:before {
	content: "\e628";
}
.uni-ribbon:before {
	content: "\e629";
}
.uni-venn-diagram:before {
	content: "\e62a";
}
.uni-car-coins:before {
	content: "\e62b";
}
.uni-cash-register2:before {
	content: "\e62c";
}
.uni-password-shopping:before {
	content: "\e62d";
}
.uni-tag-5:before {
	content: "\e62e";
}
.uni-coding:before {
	content: "\e62f";
}
.uni-consulting:before {
	content: "\e630";
}
.uni-testimonal:before {
	content: "\e631";
}
.uni-lock-2:before {
	content: "\e632";
}
.uni-unlock-2:before {
	content: "\e633";
}
.uni-atom:before {
	content: "\e634";
}
.uni-chemical:before {
	content: "\e635";
}
.uni-plaster:before {
	content: "\e636";
}
.uni-camera-2:before {
	content: "\e637";
}
.uni-flash-2:before {
	content: "\e638";
}
.uni-photo:before {
	content: "\e639";
}
.uni-photos:before {
	content: "\e63a";
}
.uni-sport-mode:before {
	content: "\e63b";
}
.uni-business-man:before {
	content: "\e63c";
}
.uni-business-woman:before {
	content: "\e63d";
}
.uni-speak-2:before {
	content: "\e63e";
}
.uni-talk-man:before {
	content: "\e63f";
}
.uni-chair:before {
	content: "\e640";
}
.uni-footprint:before {
	content: "\e641";
}
.uni-gift-box:before {
	content: "\e642";
}
.uni-key:before {
	content: "\e643";
}
.uni-light-bulb:before {
	content: "\e644";
}
.uni-luggage-2:before {
	content: "\e645";
}
.uni-paper-plane:before {
	content: "\e646";
}
.uni-environmental-3:before {
	content: "\e647";
}
.uni-compass-4:before {
	content: "\e648";
}
.uni-globe:before {
	content: "\e649";
}
.uni-map-marker:before {
	content: "\e64a";
}
.uni-map2:before {
	content: "\e64b";
}
.uni-satelite-2:before {
	content: "\e64c";
}
.uni-add:before {
	content: "\e64d";
}
.uni-close:before {
	content: "\e64e";
}
.uni-cursor-click2:before {
	content: "\e64f";
}
.uni-download-2:before {
	content: "\e650";
}
.uni-link:before {
	content: "\e651";
}
.uni-upload-2:before {
	content: "\e652";
}
.uni-yes:before {
	content: "\e653";
}
.uni-old-camera:before {
	content: "\e654";
}
.uni-mouse-4:before {
	content: "\e655";
}
.uni-coffee:before {
	content: "\e656";
}
.uni-doughnut:before {
	content: "\e657";
}
.uni-glass-water:before {
	content: "\e658";
}
.uni-hot-dog:before {
	content: "\e659";
}
.uni-juice:before {
	content: "\e65a";
}
.uni-pizza-slice:before {
	content: "\e65b";
}
.uni-pizza:before {
	content: "\e65c";
}
.uni-wine-glass:before {
	content: "\e65d";
}
.uni-box-open:before {
	content: "\e65e";
}
.uni-box-withfolders:before {
	content: "\e65f";
}
.uni-add-file:before {
	content: "\e660";
}
.uni-delete-file:before {
	content: "\e661";
}
.uni-file-download:before {
	content: "\e662";
}
.uni-file-horizontaltext:before {
	content: "\e663";
}
.uni-file-link:before {
	content: "\e664";
}
.uni-file-love:before {
	content: "\e665";
}
.uni-file-pictures:before {
	content: "\e666";
}
.uni-file-zip:before {
	content: "\e667";
}
.uni-files:before {
	content: "\e668";
}
.uni-remove-file:before {
	content: "\e669";
}
.uni-thumbs-upsmiley:before {
	content: "\e66a";
}
.uni-letter-open:before {
	content: "\e66b";
}
.uni-mail:before {
	content: "\e66c";
}
.uni-mailbox-full:before {
	content: "\e66d";
}
.uni-notepad:before {
	content: "\e66e";
}
.uni-computer:before {
	content: "\e66f";
}
.uni-laptop:before {
	content: "\e670";
}
.uni-monitor-2:before {
	content: "\e671";
}
.uni-monitor-5:before {
	content: "\e672";
}
.uni-monitor-phone:before {
	content: "\e673";
}
.uni-phone-2:before {
	content: "\e674";
}
.uni-smartphone-4:before {
	content: "\e675";
}
.uni-tablet-3:before {
	content: "\e676";
}
.uni-aa:before {
	content: "\e677";
}
.uni-brush:before {
	content: "\e678";
}
.uni-fountain-pen:before {
	content: "\e679";
}
.uni-idea:before {
	content: "\e67a";
}
.uni-marker:before {
	content: "\e67b";
}
.uni-note:before {
	content: "\e67c";
}
.uni-pantone:before {
	content: "\e67d";
}
.uni-pencil:before {
	content: "\e67e";
}
.uni-scissor:before {
	content: "\e67f";
}
.uni-vector-3:before {
	content: "\e680";
}
.uni-address-book:before {
	content: "\e681";
}
.uni-megaphone:before {
	content: "\e682";
}
.uni-newspaper:before {
	content: "\e683";
}
.uni-wifi:before {
	content: "\e684";
}
.uni-download-fromcloud:before {
	content: "\e685";
}
.uni-upload-tocloud:before {
	content: "\e686";
}
.uni-blouse:before {
	content: "\e687";
}
.uni-boot:before {
	content: "\e688";
}
.uni-bow-2:before {
	content: "\e689";
}
.uni-bra:before {
	content: "\e68a";
}
.uni-cap:before {
	content: "\e68b";
}
.uni-coat:before {
	content: "\e68c";
}
.uni-dress:before {
	content: "\e68d";
}
.uni-hanger:before {
	content: "\e68e";
}
.uni-heels:before {
	content: "\e68f";
}
.uni-jacket:before {
	content: "\e690";
}
.uni-jeans:before {
	content: "\e691";
}
.uni-shirt:before {
	content: "\e692";
}
.uni-suit:before {
	content: "\e693";
}
.uni-sunglasses-w3:before {
	content: "\e694";
}
.uni-t-shirt:before {
	content: "\e695";
}
.uni-present:before {
	content: "\e696";
}
.uni-tactic:before {
	content: "\e697";
}
.uni-bar-chart3:before {
	content: "\e698";
}
.uni-calculator-2:before {
	content: "\e699";
}
.uni-calendar-4:before {
	content: "\e69a";
}
.uni-credit-card2:before {
	content: "\e69b";
}
.uni-diamond:before {
	content: "\e69c";
}
.uni-financial:before {
	content: "\e69d";
}
.uni-handshake:before {
	content: "\e69e";
}
.uni-line-chart4:before {
	content: "\e69f";
}
.uni-money-2:before {
	content: "\e6a0";
}
.uni-pie-chart3:before {
	content: "\e6a1";
}
.uni-home:before {
	content: "\e6a2";
}
.uni-bones:before {
	content: "\e6a3";
}
.uni-brain:before {
	content: "\e6a4";
}
.uni-ear:before {
	content: "\e6a5";
}
.uni-eye-visible:before {
	content: "\e6a6";
}
.uni-face-style:before {
	content: "\e6a7";
}
.uni-fingerprint-2:before {
	content: "\e6a8";
}
.uni-heart:before {
	content: "\e6a9";
}
.uni-arrow-downincircle:before {
	content: "\e6aa";
}
.uni-arrow-left:before {
	content: "\e6ab";
}
.uni-arrow-right:before {
	content: "\e6ac";
}
.uni-arrow-up:before {
	content: "\e6ad";
}
.uni-download:before {
	content: "\e6ae";
}
.uni-fit-to:before {
	content: "\e6af";
}
.uni-full-screen:before {
	content: "\e6b0";
}
.uni-full-screen2:before {
	content: "\e6b1";
}
.uni-left:before {
	content: "\e6b2";
}
.uni-repeat-2:before {
	content: "\e6b3";
}
.uni-right:before {
	content: "\e6b4";
}
.uni-up:before {
	content: "\e6b5";
}
.uni-upload:before {
	content: "\e6b6";
}
.uni-arrow-around:before {
	content: "\e6b7";
}
.uni-arrow-loop:before {
	content: "\e6b8";
}
.uni-arrow-outleft:before {
	content: "\e6b9";
}
.uni-arrow-outright:before {
	content: "\e6ba";
}
.uni-arrow-shuffle:before {
	content: "\e6bb";
}
.uni-maximize:before {
	content: "\e6bc";
}
.uni-minimize:before {
	content: "\e6bd";
}
.uni-resize:before {
	content: "\e6be";
}
.uni-bird:before {
	content: "\e6bf";
}
.uni-cat:before {
	content: "\e6c0";
}
.uni-dog:before {
	content: "\e6c1";
}
.uni-align-center:before {
	content: "\e6c2";
}
.uni-align-left:before {
	content: "\e6c3";
}
.uni-align-right:before {
	content: "\e6c4";
}