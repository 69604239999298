#promo-animation {
    background-image: url('/images/knu-promo-link.gif');
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    display: none;
    width: 100px;
    height: 50px;
    color:#fff;
    margin-right:-40%;
    cursor:pointer;
    z-index:2;
    outline-color: darkred;
    outline-style:auto;
    outline-width:5px;
}

.highlightedmenu{
    background-color: deepskyblue !important;
}

.highlightedmenu li a{
    color: #fff !important;;
}

.highlightedmenu li li a{
    color: #777 !important;
}

.m-header-above-background-image{
    text-shadow: 0 2px 0 #666 !important;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;

    background: rgba(78, 128, 187, 0.8);
    padding: 25px;
}

.vsubmiddle{
    top: 25%;
    left:0;
    right:0;
    margin:0 auto;
    position:absolute;
    transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    -moz-transform:translateY(-50%);
    -o-transform:translateY(-50%);
    -webkit-transform:translateY(-50%);
}
.vtop{
    top: 20%;
    left:0;
    right:0;
    margin:0 auto;
    position:absolute;
    transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    -moz-transform:translateY(-50%);
    -o-transform:translateY(-50%);
    -webkit-transform:translateY(-50%);
}
.top-header-title{
    position: absolute !important;
    top: 28px;
    width: 100%;
    font-size: 30pt !important;
    margin: 0 !important;
}
.sticky-menu-active .top-header-title{
    display: none;
}
.nav-main{
    float: left !important;
    margin-left: 80px !important;
}
.slider-below-wrap{
    margin: 25px 0 !important;
    padding: 25px 25px 0 !important;
}
.sub-header-title{
    color: #4e80bb;
    font-size: 12pt;
    line-height: 20px;
    margin: 40px 50px 0 10px;
}
.sticky-menu-active .sub-header-title{
    margin: 10px 50px 0 10px;
    padding-top: 50px;
}
.knu-fixed-logo{
    position: absolute !important;
    top: 25px;
}
.sticky-menu-active .knu-fixed-logo{
    top: 15px;
}
.margin-top-m50{
    margin-top: -50px !important;
}
.header-top{
    background-color: #4e80bb !important;
    color: #fff !important;
}

#header nav ul.nav-top li span{
    color: #fff !important;
}
.pad-top-50{
    padding-top: 50px;
}
a.collapsed.m-no-icon:before{
    content: '' !important;
}

/* Lists
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.news-single-details ul {
    list-style: circle inside !important;
}
.news-single-details ol {
    list-style: decimal inside !important;
}
.news-single-details ol, .news-single-details ul {
    padding-left: 0 !important;
    margin-top: 0 !important;
}
.news-single-details ul ul,
.news-single-details ul ol,
.news-single-details ol ol,
.news-single-details ol ul {
    margin: 1.5rem 0 1.5rem 3rem !important;
    font-size: 90%; !important
}
.news-single-details li {
    margin-bottom: 1rem; !important
}
.events-widget{
    background-color: orangered;
    color: #fff;
}
.events-widget li a,
.events-widget p,
.events-widget p i{
    color: #fff;
}

.gm-loadingBackground {
    -webkit-animation: progress-bar-stripes 2s linear infinite !important;
    -o-animation: progress-bar-stripes 2s linear infinite !important;
    animation: progress-bar-stripes 2s linear infinite !important;
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, .15) 25%, rgba(0, 0, 0, .15) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, .15) 75%, rgba(0, 0, 0, .15)) !important;
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, .15) 25%, rgba(0, 0, 0, .15) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, .15) 75%, rgba(0, 0, 0, .15)) !important;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, .15) 25%, rgba(0, 0, 0, .15) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, .15) 75%, rgba(0, 0, 0, .15)) !important;
    -webkit-background-size: 40px 40px !important;
    background-size: 40px 40px !important;
    cursor: default !important;
}

.has-danger .form-control-feedback,
.has-danger .form-control-label,
.has-danger .col-form-label,
.has-danger .form-check-label,
.has-danger .custom-control {
    color: #f86c6b; }

.has-danger .form-control, .has-danger .daterangepicker .input-mini, .daterangepicker .has-danger .input-mini, .has-danger .input-group > .ui-select-bootstrap > input.ui-select-search.form-control {
    border-color: #f86c6b; }

.has-danger .input-group-addon {
    color: #f86c6b;
    border-color: #f86c6b;
    background-color: white; }

.has-danger .form-control-danger {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f86c6b' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E"); }

/* Загальні стилі */
.icon-menu-container {
    position: relative;
}

.icon-menu-checkbox {
    display: none; /* Приховуємо чекбокс */
}

.icon-menu-label {
    display: none; /* Ховаємо кнопку за замовчуванням для великих екранів */
    background-color: #1A4A9E;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 10px
}

.icon-menu-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    margin-top: 15px;
}

/* Відображаємо меню, коли чекбокс активний */
.icon-menu-checkbox:checked ~ .icon-menu-wrap {
    display: flex;
}

.icon-menu-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.icon-menu-col {
    flex: 0 0 calc(20% - 5px); /* 5 columns per row */
    height: 50px; /* Square size */
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 5px; /* Increased spacing between rows */
}

.icon-menu-content-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-decoration: none;
    background-color: #4e80bb; /* Primary background color (darker blue) */
    color: #FFFFFF; /* White text color */
    transition: background-color 0.3s ease, transform 0.3s ease;
    border-radius: 8px; /* Slightly rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for better contrast */
}

.icon-menu-content-box.icon-menu-active {
    background-color: #1A4A9E; /* Active state: darker blue */
}

.icon-menu-content-box:hover {
    background-color: #0056B3; /* Hover state: intermediate blue */
    transform: scale(1.05); /* Slightly enlarge on hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Slightly deeper shadow on hover */
}

.icon-menu-content-wrap {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 5px;
}

.icon-menu-heading {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    color: #FFFFFF; /* Ensure consistent text color */
}

/* Responsive styles */
@media (max-width: 1200px) {
    .icon-menu-col {
        flex: 0 0 calc(25% - 5px); /* 4 columns per row */
    }
}

@media (max-width: 992px) {
    .icon-menu-col {
        flex: 0 0 calc(33.33% - 5px); /* 3 columns per row */
    }
}

/* Стилі для мобільних пристроїв */
/* Приховуємо меню тільки на мобільних пристроях */
@media (max-width: 768px) {
    .icon-menu-label {
        display: block; /* Відображаємо кнопку для мобільних */
    }

    .icon-menu-wrap {
        display: none; /* Ховаємо меню за замовчуванням на мобільних */
    }

    /* Відображаємо меню, коли чекбокс активний */
    .icon-menu-checkbox:checked ~ .icon-menu-wrap {
        display: flex; /* Показуємо меню */
        flex-direction: column; /* Вертикальне меню */
        gap: 10px;
    }

    .icon-menu-col {
        flex: 0 0 100%; /* Повна ширина для кожного пункту меню */
        height: auto; /* Гнучка висота */
    }
}

@media only screen and (max-width: 1120px) {
    #header .header-top ul.nav-top li.phone{
        font-size: 14px !important;
    }
    #header nav ul.nav-main > li > a {
        font-size: 10px;
    }

    .top-header-title{
        font-size: 20pt;
        line-height: 35px;
        top:20px;
        width: auto;
        margin: 0 100px !important;
    }
}
@media only screen and (max-width: 991px) {
    .nav-main{
        margin-left: 0px !important;
    }
    .knu-fixed-logo{
        top: 0px !important;
    }

    .sticky-menu-active .knu-fixed-logo{
        top: 30px !important;
    }


    .top-header-title{
        font-size: 18pt !important;
        top: 40px !important;
        margin:0 200px !important;
    }

    .margin-top-m50{
        margin-top: 0px !important;
    }
}

@media only screen and (max-width: 800px) {
    .subnav .heading {
        font-size: 11px !important;
    }

    .subnav .col-sm-1{
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    .top-header-title{
        top: 30px !important;
        margin:0 150px !important;
        line-height: 20px !important;
        font-size: 15pt !important;
    }

    .owl-item h5{
        font-size: 12px;
        line-height: 18px;
    }

    .m-header-above-background-image{
        padding:25px 50px !important;
    }
}

@media only screen and (max-width: 600px) {
    .top-header-title{
        margin:0 100px !important;
    }
}

/*  Bootstrap Clearfix */

/*  Tablet  */
@media (min-width:767px){

    /* Column clear fix */
    .grid .col-lg-1:nth-child(12n+1),
    .grid .col-lg-2:nth-child(6n+1),
    .grid .col-lg-3:nth-child(4n+1),
    .grid .col-lg-4:nth-child(3n+1),
    .grid .col-lg-6:nth-child(2n+1),
    .grid .col-md-1:nth-child(12n+1),
    .grid .col-md-2:nth-child(6n+1),
    .grid .col-md-3:nth-child(4n+1),
    .grid .col-md-4:nth-child(3n+1),
    .grid .col-md-6:nth-child(2n+1){
        clear: none;
    }
    .grid .col-sm-1:nth-child(12n+1),
    .grid .col-sm-2:nth-child(6n+1),
    .grid .col-sm-3:nth-child(4n+1),
    .grid .col-sm-4:nth-child(3n+1),
    .grid .col-sm-6:nth-child(2n+1){
        clear: left;
    }
}


/*  Medium Desktop  */
@media (min-width:992px){

    /* Column clear fix */
    .grid .col-lg-1:nth-child(12n+1),
    .grid .col-lg-2:nth-child(6n+1),
    .grid .col-lg-3:nth-child(4n+1),
    .grid .col-lg-4:nth-child(3n+1),
    .grid .col-lg-6:nth-child(2n+1),
    .grid .col-sm-1:nth-child(12n+1),
    .grid .col-sm-2:nth-child(6n+1),
    .grid .col-sm-3:nth-child(4n+1),
    .grid .col-sm-4:nth-child(3n+1),
    .grid .col-sm-6:nth-child(2n+1){
        clear: none;
    }
    .grid .col-md-1:nth-child(12n+1),
    .grid .col-md-2:nth-child(6n+1),
    .grid .col-md-3:nth-child(4n+1),
    .grid .col-md-4:nth-child(3n+1),
    .grid .col-md-6:nth-child(2n+1){
        clear: left;
    }
}


/*  Large Desktop  */
@media (min-width:1200px){

    /* Column clear fix */
    .grid .col-md-1:nth-child(12n+1),
    .grid .col-md-2:nth-child(6n+1),
    .grid .col-md-3:nth-child(4n+1),
    .grid .col-md-4:nth-child(3n+1),
    .grid .col-md-6:nth-child(2n+1),
    .grid .col-sm-1:nth-child(12n+1),
    .grid .col-sm-2:nth-child(6n+1),
    .grid .col-sm-3:nth-child(4n+1),
    .grid .col-sm-4:nth-child(3n+1),
    .grid .col-sm-6:nth-child(2n+1){
        clear: none;
    }
    .grid .col-lg-1:nth-child(12n+1),
    .grid .col-lg-2:nth-child(6n+1),
    .grid .col-lg-3:nth-child(4n+1),
    .grid .col-lg-4:nth-child(3n+1),
    .grid .col-lg-6:nth-child(2n+1){
        clear: left;
    }
}

.animated-menu,
.animated-menu-2
{
    animation: shadow-pulse 1s infinite;
}

@keyframes shadow-pulse
{
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    }
}

@keyframes shadow-pulse-big
{
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
    }
    100% {
        box-shadow: 0 0 0 70px rgba(0, 0, 0, 0);
    }
}