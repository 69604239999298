
/* -------------------------------- 

Timeline Css 

-------------------------------- */
.universh-container {
	width: 90%;
	max-width: 1170px;
	margin: 0 auto;
}
.universh-container::after {
	content: '';
	display: table;
	clear: both;
}

/* -------------------------------- 

Main components 

-------------------------------- */
#universh-timeline {
	position: relative;
	padding: 75px 0;
}
#universh-timeline::before {
	/* this is the vertical line */
	content: '';
	position: absolute;
	top: 0;
	left: 18px;
	height: 100%;
	width: 4px;
	background: #d7e4ed;
}
@media only screen and (min-width: 1170px) {
	#universh-timeline::before {
		left: 50%;
		margin-left: -2px;
	}
}

.universh-timeline-block {
	position: relative;
	margin: 2em 0;
}
.universh-timeline-block:after {
	content: "";
	display: table;
	clear: both;
}
.universh-timeline-block:first-child {
	margin-top: 0;
}
.universh-timeline-block:last-child {
	margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
	.universh-timeline-block {
		margin: 4em 0;
	}
	.universh-timeline-block:first-child {
		margin-top: 0;
	}
	.universh-timeline-block:last-child {
		margin-bottom: 0;
	}
}
.universh-timeline-img span{
	color: #fff;
	font-size: 14px;
	margin-top: 20px;
	display: block;
}
.universh-timeline-img i {
    color: #fff;
    font-size: 18px;
    position: absolute;
    text-align: center;
    top: 50%;
	left: 50%;
    transform: translateY(-50%) translateX(-50%); 
	-webkit-transform: translateY(-50%) translateX(-50%); 
	-ms-transform: translateY(-50%) translateX(-50%); 
	-o-transform: translateY(-50%) translateX(-50%);
}
.universh-timeline-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	text-align: center;
	-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	   -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
		-ms-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
		 -o-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.universh-timeline-img img {
	display: block;
	width: 24px;
	height: 24px;
	position: relative;
	left: 50%;
	top: 50%;
	margin-left: -12px;
	margin-top: -12px;
}
.universh-timeline-img.universh-picture {
	background: #75ce66;
}
.universh-timeline-img.universh-movie {
	  background: #c03b44;
}
.universh-timeline-img.universh-location {
	  background: #f0ca45;
}
@media only screen and (min-width: 1170px) {
.universh-timeline-img {
	width: 60px;
	height: 60px;
	left: 50%;
	margin-left: -30px;
	/* Force Hardware Acceleration in WebKit */
	-webkit-transform: translateZ(0);
	-webkit-backface-visibility: hidden;
}
.cssanimations .universh-timeline-img.is-hidden {
	visibility: hidden;
}
.cssanimations .universh-timeline-img.bounce-in {
	visibility: visible;
	-webkit-animation: universh-bounce-1 0.6s;
	-moz-animation: universh-bounce-1 0.6s;
	animation: universh-bounce-1 0.6s;
	}
}

@-webkit-keyframes universh-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes universh-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(1);
  }
}
@keyframes universh-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.universh-timeline-content {
	position: relative;
	margin-left: 60px;
	background: white;
	border-radius: 0.25em;
	padding: 1em;
	box-shadow: 0 3px 0 #d7e4ed;
}
.universh-timeline-content:after {
	content: "";
	display: table;
	clear: both;
}
.universh-timeline-content h2 {
	color: #303e49;
}
.universh-timeline-content p, .universh-timeline-content .universh-read-more, .universh-timeline-content .universh-date {
}
.universh-timeline-content .universh-read-more, .universh-timeline-content .universh-date {
	display: inline-block;
}
.universh-timeline-content p {
	margin: 1em 0;
	line-height: 1.6;
}
.universh-timeline-content .universh-read-more {
	float: right;
	padding: .8em 1em;
	background: #acb7c0;
	color: white;
	border-radius: 0.25em;
}
.no-touch .universh-timeline-content .universh-read-more:hover {
	background-color: #bac4cb;
}
.universh-timeline-content .universh-date {
	padding: .8em 0;
	margin-left: 10px;
}
.universh-timeline-content::before {
	content: '';
	position: absolute;
	top: 16px;
	right: 100%;
	height: 0;
	width: 0;
	border: 7px solid transparent;
	border-right: 7px solid white;
}
@media only screen and (min-width: 768px) {
  .universh-timeline-content h2 {
  
  }
  .universh-timeline-content p {
   
  }
  .universh-timeline-content .universh-read-more, .universh-timeline-content .universh-date {
  
  }
}
@media only screen and (min-width: 1170px) {
	.universh-timeline-content {
		margin-left: 0;
		padding: 1.6em;
		width: 45%;
	}
	.universh-timeline-content::before {
		top: 24px;
		left: 100%;
		border-color: transparent;
		border-left-color: white;
	}
	.universh-timeline-content .universh-read-more {
		float: left;
	}
	.universh-timeline-content .universh-date {
		position: absolute;
		width: 100%;
		left: 122%;
		top: 6px;
	}
	.universh-timeline-block:nth-child(even) .universh-timeline-content {
		float: right;
	}
	.universh-timeline-block:nth-child(even) .universh-timeline-content::before {
		top: 24px;
		left: auto;
		right: 100%;
		border-color: transparent;
		border-right-color: white;
	}
	.universh-timeline-block:nth-child(even) .universh-timeline-content .universh-read-more {
		float: right;
	}
	.universh-timeline-block:nth-child(even) .universh-timeline-content .universh-date {
		left: auto;
		right: 122%;
		text-align: right;
	}
	.cssanimations .universh-timeline-content.is-hidden {
		visibility: hidden;
	}
	.cssanimations .universh-timeline-content.bounce-in {
		visibility: visible;
		-webkit-animation: universh-bounce-2 0.6s;
		-moz-animation: universh-bounce-2 0.6s;
		animation: universh-bounce-2 0.6s;
	}
}

@media only screen and (min-width: 1170px) {
	/* inverse bounce effect on even content blocks */
	.cssanimations .universh-timeline-block:nth-child(even) .universh-timeline-content.bounce-in {
		-webkit-animation: universh-bounce-2-inverse 0.6s;
		-moz-animation: universh-bounce-2-inverse 0.6s;
		animation: universh-bounce-2-inverse 0.6s;
	}
}
@-webkit-keyframes universh-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes universh-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes universh-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes universh-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes universh-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes universh-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@media only screen and (max-width: 1170px) {
	.universh-timeline-img i{
		font-size: 20px;
	}
	.universh-timeline-img span{
		font-size: 14px;
		margin-top: 10px;
	}
}