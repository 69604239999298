/* ================================= COLOR STYLE SHEET ====================================== */
a,
a:hover, a:focus, a.active,
.typo-dark h1 a:hover, .typo-dark h1 a:focus, .typo-dark h1 a.active,
.typo-dark h2 a:hover, .typo-dark h2 a:focus, .typo-dark h2 a.active,
.typo-dark h3 a:hover, .typo-dark h3 a:focus, .typo-dark h3 a.active, 
.typo-dark h4 a:hover, .typo-dark h4 a:focus, .typo-dark h4 a.active, 
.typo-dark h5 a:hover, .typo-dark h5 a:focus, .typo-dark h5 a.active, 
.typo-dark h6 a:hover, .typo-dark h6 a:focus, .typo-dark h6 a.active,

.typo-light h1 a:hover, .typo-light h1 a:focus, .typo-light h1 a.active,
.typo-light h2 a:hover, .typo-light h2 a:focus, .typo-light h2 a.active,
.typo-light h3 a:hover, .typo-light h3 a:focus, .typo-light h3 a.active, 
.typo-light h4 a:hover, .typo-light h4 a:focus, .typo-light h4 a.active, 
.typo-light h5 a:hover, .typo-light h5 a:focus, .typo-light h5 a.active, 
.typo-light h6 a:hover, .typo-light h6 a:focus, .typo-light h6 a.active
{
	color: #4e80bb;
}
.btn,.btn.dark:hover{
	background-color: #4e80bb;
}





/* Color */
.color{
	color: #4e80bb;
}

/* Background Color */
.bg-color{
	background-color: #4e80bb !important;
}
/* Bg Gradient */
.bg-gradient{
	background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(68, 138, 255, 0.8) 0%, rgba(35, 35, 35, 0.8) 100%) repeat scroll 0 0;
}
/* Overlay */
.overlay.bg-color:after{
	background-color: #4e80bb;
}
/* Background Video */
.video-controls a{
	background-color: #4e80bb;
}
/* Title */
.title-container.color *,
.title-container .dot-separator.color{
	color: #4e80bb;
}

.title-container.color .title-wrap .separator.line-separator,
.title-container.color .title-wrap .separator.line-separator:after{
	background-color: #4e80bb;
}
.title-bg-line > .title a{
   background-color: #4e80bb;
}
.title-bg-line{
	border-color: #4e80bb;
}
/*==================== 
	Bootstrap 
====================== */
/* Accordion */
.panel-group .panel{
	border-left-color: #4e80bb;
}
/* Tab */
.nav-tabs > li > a:hover,.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover{
	color: #4e80bb;
	border-bottom-color: #4e80bb;
}
/* Progress */
.progress-bar{
	background-color: #4e80bb;
}
/* List Types */
.list-icon li:hover:before{
	color: #4e80bb;
}
/* Pagination */
.pagination > li > a.active, .pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover,
.pager li > a:focus, .pager li > a:hover{
	background-color: #4e80bb;
}

/* Back to top */
.cd-top:hover, .cd-top:focus {
	background-color: #4e80bb;
}

/* Tooltip */
.tooltip-color .tooltip-inner{
	background-color: #4e80bb;
}
.tooltip-color .tooltip.top .tooltip-arrow{
	border-top-color:#4e80bb;
}

.tooltip-color .tooltip.bottom .tooltip-arrow{
	border-bottom-color:#4e80bb;
}

.tooltip-color .tooltip.left .tooltip-arrow{
	border-left-color:#4e80bb;
}

.tooltip-color .tooltip.right .tooltip-arrow{
	border-right-color:#4e80bb;
}

/* Content box */
.content-box i{
	color:#4e80bb;
}
.content-box.icon-box .icon-wrap i{
	background-color: #4e80bb;
}
/* Cat */
.cat{
	background-color: #4e80bb;
}



/* Team */
.color-border .member-wrap{
	border-color: #4e80bb;
}
.team-list .member-detail-wrap .position {
	color: #4e80bb;
}

/*==================== 
	Course 
====================== */
.course-banner-wrap > h6 {
	background-color: #4e80bb;
}
.course-wrapper h5 span{
	color: #4e80bb;
}

/*==================== 
	Timeline 
====================== */
.universh-timeline-img{
	background-color: #4e80bb;
}
/*==================== 
	Events 
====================== */
.events-meta li i, .event-widget p i{
	color: #4e80bb;
}
/* Testimonial */
.quote-footer span{
	color: #4e80bb;
}
.quote-wrap.color > blockquote{
	background-color: #4e80bb;
}
/* Page Header */
.page-header .breadcrumb li, .page-header .breadcrumb li a:hover, .page-header .breadcrumb li a:focus{
	color: #4e80bb;
}
.page-header .title::after,
.page-header .title::before{
	background-color: #4e80bb;
}
/* Related Block */
.related-content a{
	background-color: #4e80bb;
}
/*==================== 
	Widgets 
====================== */
.widget-title span{
	background-color: #4e80bb;
}
.widget li a:hover, .widget li a:focus, .widget li a.active,
.widgets-dark.typo-light .widget li a:hover, .widgets-dark.typo-light .widget li a:focus, .widgets-dark.typo-light .widget li a.active{
	color: #4e80bb;
}
/* Tag Widget */
.tag-widget li a:hover, .tag-widget li a:focus, .tag-widget li a.active{
	background-color: #4e80bb;
	border-color: #4e80bb;
}
.widgets-dark.typo-light .tag-widget li a:hover, .widgets-dark.typo-light .tag-widget li a:focus, .widgets-dark.typo-light .tag-widget li a.active{
	color: #fff;
}
/* Go widget */
.go-widget li a:hover .badge, .accordion-widget .panel-default > .panel-heading a:hover .badge{
	background-color: #4e80bb;
}
.widgets-dark.typo-light .widget .accordion-widget .panel-default > .panel-heading:hover .badge{
	background-color: #4e80bb;
}
.widgets-dark.typo-light .widget.no-box .accordion-widget .panel-default > .panel-heading:hover .badge{
	background-color: #4e80bb;
}
/* Thumbanil Widget */
.thumbnail-widget span.color{
	color: #4e80bb;
}
/*==================== 
	Owl Carousel
====================== */
.owl-theme .owl-nav > div:hover {
	background-color:#4e80bb;
}
.owl-theme .owl-dots .owl-dot:hover span, .owl-theme .owl-dots .owl-dot.active span{
	background-color:#4e80bb;
}


/* Gallery */
.accordion.gallery-accordion .panel-title a.collapsed{
	background:rgba(33, 150, 243, 0.9);
}
.isotope-filters .nav-pills li a.active, .isotope-filters .nav-pills li a:hover, .isotope-filters .nav-pills li a:focus{
	background-color:#4e80bb;
}
/* News */
.news-cat{
	background-color:#4e80bb;
}

/* Shop */
.product-label, .product-details .option-btn, .shop-meta li a:hover, .shop-meta li a:focus{
	background-color:#4e80bb;
}
.liked-members a:hover,.liked-members a:focus{
	background-color:#4e80bb;
}


/* Footer */
.footer-copyright nav ul li a:hover, .footer-copyright nav ul li a:focus{
	color: #4e80bb;
}

/*==================== 
	Header  color
====================== */
#header nav ul.nav-main li.dropdown.open .dropdown-toggle,
#header nav ul.nav-main li.active a,
#header nav ul.nav-main li > a:hover,
#header nav ul.nav-main li.dropdown:hover a,
#header.flat-menu nav ul.nav-main li.active > a {
	background-color: #4e80bb;
}

#header nav ul.nav-main ul.dropdown-menu {
	border-color: #4e80bb;
}

#header nav ul.nav-main li.dropdown:hover ul.dropdown-menu li > a:hover {
	background: #4e80bb ;
}
#header .tip{
	background: #4e80bb;
}
#header .tip:before{
	border-right-color: #4e80bb;
}
@media (min-width: 992px) {
	#header.single-menu nav ul.nav-main li.active > a {
		border-top: 5px solid #4e80bb;
	}

	#header.darken-top-border {
		border-color: #4e80bb;
	}

	#header.colored .header-top {
		background-color: #4e80bb;
	}

	#header.colored .header-top ul.nav-top li a:hover {
		background-color: #4e80bb;
	}
	
	/*#header.flat-menu nav ul.nav-main li.active > a {
		color: #4e80bb;
	}*/

	#header.flat-menu nav ul.nav-main li > a:hover,
	#header.flat-menu nav ul.nav-main li.dropdown:hover a {
		background-color: #4e80bb;
	}

	#header nav.mega-menu ul.nav-main li.mega-menu-item ul.dropdown-menu {
		border-top-color: #4e80bb;
	}
}




