/* ================================= RESPONSIVE STYLE SHEET ======================================= */
/* Max Width 1024px */
@media (max-width:1024px) {
	.team-list .member-img-wrap img {
		position: relative;
		-webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
		   -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
			-ms-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
			 -o-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
				box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
	}
	/* Align none */
	.vmiddle.position-none-1024{
		position: relative;
		top: auto;
		transform: none;
		-webkit-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		-o-transform: none;
	}
	/* gallery */
	.isotope-grid .item img{
		width: 100%;
	}
	/* Evenet Hero */
	.hero-scene-event h2{
		margin: 20px 0;
		line-height: 32px;
		font-size: 28px;
	}
	.hero-scene-event .sub-title{
		font-size: 20px;
	}
	
	
	
	
}
/* Max Width 1024px  to min With 768*/
@media only screen and (max-width: 1024px) and (min-width: 767px) {
	/* Spacings */
	.margin-top-30-sm-only{
		margin-top: 30px;
	}
	/* Header */
	#header .tip{
		display: none;
	}
	/* Evenet Hero */
	.hero-scene-event h2{
		margin-top: 30px;
	}
	/* Blog */
	.blog-meta li{
		font-size: 10px;
	}
	.blog-meta li i {
		font-size: 12px;
		height: auto;
		line-height: auto;
		width: auto;
		background: transparent;
	}
	.post-type {
		height: 30px;
		width: 30px;
		bottom: -30px;
		line-height: 30px;
		font-size: 11px;
	}
	/* Course */
	.course-wrapper .teacher-wrap {
		margin-bottom: 10px;
		padding-left: 70px;
	}
	.course-wrapper .teacher-wrap img {
		border-width: 2px;
		height: 60px;
		left: 0;
		width: 60px;
	}
	.course-wrapper .teacher-wrap h5{
		font-size: 18px;
	}
	/* Pricing */
	.pricing-wrap{
		padding: 30px 0;
	}
	.pricing-title > h4 {
		font-size: 26px;
	}
	.pricing-icon img{
		height: 80px;
		width: 80px;
	}
	.pricing-body > li {
		font-size: 18px;
		padding: 10px 20px;
	}
	li > .duration {
		font-size: 11px;
		margin-top: 6px;
	}
	.pricing-footer {
		padding: 30px 15px 0;
	}
	.pricing-footer .btn {
		height: 40px;
		font-size: 13px;
		line-height: 22px;
	}
	/* Contact Box */
	.contact-info{
		padding: 0 20px 20px;
	}
	.contact-info .title{
		font-size: 20px;
	}
	/* Testimonial */
	.quote-footer .name{
		font-size: 16px;
	}
	.quote-footer span {
		font-size: 14px;
	}
	/* Pie Chart */
	.piechart canvas{
		width: 100%;
	}
	/* Shop */
	.shop-title-wrap{
		display: block;
	}
	.shop-btns {
		float: none;
		margin-top: 10px;
	}
	
}



/* Max Width 991px */
@media (min-width:991px) {
	/* Footer */
	footer .widget:last-child {
		margin-bottom: 0;
	}
	.isotope-grid.grid-three-column .item:last-child,
	.isotope-grid.grid-three-column .item:nth-last-child(-n+2),
	.isotope-grid.grid-three-column .item:nth-last-child(-n+3){
		margin-bottom: 0 !important;
	}
	
}
/* Max Width 1024px  to min With 768*/
@media only screen and (max-width: 991px) and (min-width: 767px) {
	/* Spacings */
	.margin-top-30-sm-only{
		margin-top: 30px;
	}
	.blog-details > h5{
		font-size: 18px;
	}
	.member-detail-wrap .member-name{
		font-size: 26px;
	}
	.event-details h4 {
		font-size: 28px;
	}	
}	

/* Max Width 991px */
@media (max-width:991px) {
	.hidden-991{
		display: none;
	}
	/* Spacing */
	.margin-top-991-30{
		margin-top: 30px;
	}
	.margin-top-991-20{
		margin-top: 20px;
	}
	.margin-bottom-991-20{
		margin-bottom: 20px;
	}
	.vmiddle.position-none-991{
		position: relative;
		top: auto;
		transform: none;
		-webkit-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		-o-transform: none;
	}
	/* Owl Hero */
	.hero .owl-carousel .item .slider-content h3{
		font-size: 24px;
		margin-bottom: 10px;
	}
	/* Event Hero */
	.hero-scene-event .sub-title{
		max-width: auto;
	}
	.hero-scene-event .countdown-section{
		display: inline-block;
		background: transparent;
		width: 100px;
		height: 100px;
		margin: 30px 10px 0;
	}
	.hero-scene-event .countdown-section .countdown-amount{
		font-size: 20px;
		margin: 0;
	}
	/* Pagination */
	.pagination > li > a, .pagination > li > span, .pagination-lg > li > a, .pagination-lg > li > span{
		padding: 6px 10px;
	}
	.pagination-sm > li > a, .pagination-sm > li > span{
		padding: 6px 8px;
	}
	/* Team */
	.team-container.small .member-wrap {
		margin-bottom: 30px;
	}
	.team-container.small .member-wrap img {
		width: 100%;
	}
	.team-list .member-detail-wrap {
		padding: 30px 0 30px;
	}
	.team-single .owl-carousel .owl-item img{
		margin-left: auto;
		margin-right: auto;
	}
	/* Event */
	.event-sm-col .event-wrap{
		margin-bottom: 30px;
	}
	.event-sm-col .event-wrap:hover .event-details{
		margin: 0;
	}
	/* Event Single */
	.event-single-details{
		padding-top: 30px;
	}
	/* Blog */
	.blog-sm-col .blog-wrap{
		margin-bottom: 30px;
	}
	.blog-sm-col hr.hidden-md{
		display: none;
	}
	/* News */
	.news-sm-col .news-wrap{
		margin: 0 auto 30px;
	}
	.news-sm-col .news-wrap img{
		width: 100%;
	}
	/* Course */
	.course-sm-col .course-wrapper{
		margin-bottom: 30px;
	}
	/* Pricing */
	.pricing-sm-col .pricing-wrap{
		margin-bottom: 30px;
	}
	/* Shop */
	.shop-sm-col .shop-wrap{
		margin-bottom: 30px;
	}
	/* Shop Single */
	.shop-single .shop-detail-wrap{
		margin-top: 30px;
	}	
	/* Counter */
	.counter-sm .count-block{
		margin-bottom: 30px;
	}
	/* Project Single */
	.project-authors {
		position: relative;
		padding-left: 0;
		padding-right: 0;
		box-shadow: none; 
		-webkit-box-shadow: none; 
		-moz-box-shadow: none; 
		-ms-box-shadow: none; 
		-o-box-shadow: none; 
	}
	/* Related Wrapper */
	.related-wrap .img-wrap img {
		width: 100%;
	}
	/* Testimonial */
	.quote-sm .quote-wrap {
		margin-bottom: 50px;
	}
	/* Layout */
	aside.sidebar{
		margin-top:30px;
	}
	/* Page Templates 404 */
	.template-box.box-404 li.logo-wrap {
		border: medium none;
		display: block;
		margin: 0 auto;
		padding: 0;
		position: relative;
		text-align: center;
		transform: none; -webkit-transform: none; -moz-transform: none; -ms-transform: none; -o-transform: none;
	}
	.template-box.box-404 li.template-content {
		display: block;
		padding-left: 0;
	}
	/* Widget */
	.widget .news-wrap {
		width: 50%;
	}
	.widget .news-wrap img{
		width: 100%;
	}
	/* Footer */
	.gallery-widget li {
		width: auto;
	}
	.footer-copyright nav{
		float: none;
	}
	footer .col-md-3:last-child .widget,
	footer .col-md-5:last-child .widget {
		margin-bottom: 0;
	}
	.footer-copyright .logo{
		margin-bottom: 8px;
	}
	/* Element */
	.element-animation .col-eq-height {
		border-bottom: 4px solid #606060;
	}
	/* Shop */
	.product-label {
		font-size: 11px;
		height: 40px;
		line-height: 40px;
		width: 40px;
	}
}

/* Max Width 800px */
@media (max-width:800px) {

	/* Slider Below Section */
	.slider-below-section.pad-bottom-50 {
		padding-top: 60px;
	}
	.slider-below-wrap {
		margin-top: 0;
		position: relative;
		z-index: 1;
		padding: 30px 26px;
	}
	/* Call to action */
	.callto-action .title .call-title-text{
		display: block;
	}
	.callto-action .btn{
		margin-top: 30px;
	}
	.callto-action .btn, .callto-action:hover .btn {
		margin-left: 0;
		margin-right: 0;
	}
	/* Counter Box */
	.content-box .heading{
		font-size: 24px;
	}

}

/* Max Width 767px */
@media (max-width:767px) {
	.hidden-767{
		display: none;
	}
	/* Spacing */
	.margin-top-767-30{
		margin-top: 30px;
	}
	.vmiddle.position-none-767{
		position: relative;
		top: auto;
		transform: none;
		-webkit-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		-o-transform: none;
	}
	/* Video Controls */
	.video-bg .video-controls{
		right: 10px;
		top: 10px;
	}
	.video-controls a {
		height: 24px;
		width: 24px;
		line-height: 23px;
		font-size: 8px;
	}
	/* Theme Panel */
	#theme-panel{
		display: none;
	}
	/* Page Header */
	.page-header{
		padding: 30px 0;
	}
	/* Section */
	section, .page-default{
		padding-top: 60px;
		padding-bottom: 60px;
	}
	section.pad-none, .page-default.pad-none{
		padding: 0;
	}
	h1 {
		font-size: 54px;
		margin: 0 0 40px 0;
	}
	h2 {
		font-size: 44px;
		margin: 0 0 26px 0;
	}
	h3 {
		font-size: 34px;
		margin-bottom: 23px;
	}
	h4 {
		font-size: 28px;
		margin: 0 0 10px 0;
	}
	h5 {
		font-size: 20px;
		margin: 0 0 10px 0;
	}
	h6 {
		font-size: 14px;
		margin: 0 0 14px 0;
	}
	/* Section title */
	.title-container{
		margin-bottom: 40px;
	}
	.title-wrap{
		padding-bottom: 40px;
	}
	.title-container .description{
		padding-left: 0;
		padding-right: 0;
	}
	.title-wrap .separator.line-separator{
		bottom: 7px;
	}
	/* Column Equal height */
	.col-sm-6.col-eq-height.pad-60{
		padding: 30px 15px;
	}
	/* Owl */
	.owl-carousel .owl-item img{
		margin-left: auto;
		margin-right: auto;
	}
	.owl-theme .owl-dots .owl-dot span{
		height: 10px;
		width: 10px;
	}
	.owl-carousel.nav-topright .owl-controls .owl-nav div, .owl-carousel.nav-topleft .owl-controls .owl-nav div {
		top: -30px;
		height: 30px;
		width: 30px;
		line-height: 30px;
	}
	.owl-carousel .owl-controls .owl-nav .owl-prev::after, .owl-carousel .owl-controls .owl-nav .owl-next::after{
		font-size: 16px;
	}
	
	/* Page Header */
	.page-header .breadcrumb{
		margin-top:  20px;
		position: relative;
		right: 0;
		top: auto;
		-webkit-transform: none;
		   -moz-transform: none;
			-ms-transform: none;
			 -o-transform: none;
				transform: none;
	}
	.page-header .title {
		font-size: 32px;
		margin-bottom: 15px;
		padding-bottom: 15px;
	}
	/* Counter Box */
	.content-box{
		margin-bottom: 30px;
	}
	.content-box .heading{
		font-size: 24px;
	}
	/* Counter */
	.count-block{
		margin-bottom: 30px;
	}
	.col-sm-4:last-child .count-block{
		margin-bottom: 0;
	}
	/* Team */
	.member-wrap {
		margin: 0 auto 30px;
		width: 70%;
	}
	.member-img-wrap img{
		width: 100%;
	}
	.border-style .member-wrap{
		margin-bottom: 100px;
	}
	.border-style .col-sm-4:last-child .member-wrap{
		margin-bottom: 0;
	}
	/* News */
	.news-wrap{
		margin: 0 auto 30px;
		width: 100%;
	}
	.news-wrap img{
		width: 100%;
	}
	/* Slider News */
	.owl-carousel .news-wrap{
		margin: 0;
		width: 100%;
	}
	.owl-carousel .news-content{
	}
	/* Tab Title With news */
	/*.news-wrap{
		margin-left: 0;
		margin-right: 0;
	}*/
	/* Student */
	.student-wrap{
		margin-bottom: 30px;
	}
	/* Course */
	.course-wrapper{
		width: 75%;
		margin: 0 auto 30px;
	}
	.course-wrapper .course-banner-wrap img{
		width: 100%;
	}
	.col-sm-4:last-child .course-wrapper{
		margin-bottom: 0;
	}
	/* Course List */
	.course-list .course-wrapper{
		width: auto;
	}
	.course-list .course-detail-wrap{
		padding-top: 20px;
	}
	/* Course Single */
	.course-detail {
		padding-top: 30px;
	}
	/* Events */
	.event-wrap {
		margin: 0 auto 30px;
		width: 70%;
	}
	.event-wrap:hover .event-details{
		margin: 0;
	}
	.event-list-wrap .event-details{
		padding-top: 20px;
	}
	/* Blog */
	.blog-wrap {
		margin: 0 auto 30px;
		width: 70%;
	}
	.blog-list-wrap .blog-wrap {
		margin: 0;
		width: 100%;
	}
	.blog-list-wrap .blog-details{
		padding-top: 20px;
	}
	.blog-large-wrap .blog-wrap{
		width: 100%;
	}
	/* Pricing */
	.pricing-wrap {
		margin: 0 auto 30px;
	}
	/* Related */
	.related-wrap {
		margin: 0 auto 30px;
		width: 70%;
	}
	.related-wrap img, .owl-carousel .owl-item .related-wrap img{
		width: 100%;
	}
	/* Testimonial */
	.quote-wrap {
		margin-bottom: 50px;
	}
	.owl-carousel .owl-item .quote-author img, .quote-author img{
		height: 70px;
		width: 70px;
		margin-top: -24px;
		border-width: 3px;
	}
	.quote-footer .name {
		margin-top: 7px;
	}
	/* Gallery */
	.isotope-filters .nav-pills li {
		min-width: auto;
		margin-bottom: 10px;
	}
	/* Pie chart */
	.piechart{
		margin-bottom: 30px;
	}
	/* Widget */
	.widget .news-wrap {
		width: 100%;
	}
	/* Footer top */
	.footer-top-right.text-right{
		text-align: left;
	}
	.footer-top-right .widget.no-box{
		margin: 20px 0 0;
	}
	footer .col-md-9:last-child .widget{
		margin-bottom: 0;
	}
	/* page Temaplates */
	.template-box{
		text-align: center;
	}
	.template-box..box-404 li.logo-wrap {
		border-right: none;
		border-bottom: 1px solid #f1f1f1;
		padding: 0 0 20px 0;
		position: relative;
		top: auto;
		transform: none; -webkit-transform: none; -moz-transform: none; -ms-transform: none; -o-transform: none;
	}
	.template-box.box-404 li.template-content{
		padding: 20px 0 0;
	}
	.page-default .template-box{
		padding: 20px;
	}
	.template-box .countdown-section{
		width: 25%;
		margin-bottom: 20px;
	}
	/* footer */
	.footer-4 .contact-widget.text-right, .footer-4 .contact-widget.text-left{
		text-align: center;
	}
	/* Element Animation */
	.element-animation .col-eq-height h2{
		font-size: 30px;
	}
	/* Contact Info */
	.contact-info{
		margin-bottom: 30px;
	}
	
}

/* Max Width 640px */
@media (max-width: 640px) {
	.btn {
		padding: 6px 18px;
		font-size: 12px;
	}
	/* Revolution Slider */
	.tparrows {
		height: 25px;
		width: 25px;
	}
	.tparrows:before {
		line-height: 25px;
		font-size: 14px;
	}
	/* Owl Hero */
	.hero .owl-carousel .item .slider-content p, .hero .owl-carousel .item .slider-content p .btn{
		display: none;
	}
	/* Evenet Hero */
	.hero-scene-event h2{
		margin: 30px 0 15px;
		font-size: 20px;
	}
	.hero-scene-event .sub-title{
		font-size: 14px;
	}
	/* Pricing */
	.pricing-footer .btn{
		height: auto;
	}
	/* Tab */
	.nav-tabs > li > a {
		font-size: 15px;
		padding: 6px 16px;
	}
	.tab-content{
		padding: 18px 16px;
	}
	/* Forms */
	.form-control{
		height: 50px;
	}
	.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group{
		height: 50px;
		line-height: 38px;
	}
	/* Blockquote */
	blockquote{
		font-size: 12px;
	}
	.quote-wrap > blockquote{
		padding: 25px 50px;
	}
	/* Testimonial */
	.owl-carousel .owl-item .quote-author img {
		border-width: 0;
		height: 55px;
		margin-top: -10px;
		width: 55px;
		margin-right: 10px;
	}
	/* List Item */
	.list-icon li{
		font-size: 14px;
		padding-left: 30px;
		margin-bottom: 15px;
	}
	.list-icon li::before{
		font-size: 16px;
		top: -1px;
	}
	/* Course */
	.course-wrapper{
		width: 100%;
	}
	.course-wrapper .teacher-wrap{
		padding-left: 100px;
		margin-bottom: 18px;
	}
	.course-wrapper .teacher-wrap img{
		height: 80px;
		width: 80px;
		border-width: 2px;
	}
	/* Team */
	.member-wrap{
		width: 100%;
	}
	/* Events */
	.event-wrap {
		width: 100%;
	}
	/* Related Block */
	.related-wrap {
		width: 100%;
	}
	/* News */
	.news-wrap{
		width: 100%;
	}
	/* Blog */
	.blog-wrap {
		width: 100%;
	}
	.blog-large-wrap .share{
		float: none;
		margin-top: 20px;
	}
	ul.comments li {
		clear: both;
		padding: 0 0 0 10px;
	}

	ul.comments li .img-thumbnail {
		display: none;
	}

	ul.comments .comment-arrow {
		display: none;
	}
	.blog-meta li i{
		margin-bottom: 5px;
	}
	/* gallery */
	.accordion.gallery-accordion .panel-title a{
		font-size: 14px;
		padding: 6px 14px;
	}
	.accordion.gallery-accordion .panel-title a::before{
		font-size: 22px;
	}
	.gallery-accordion .panel-body{
		padding: 0 14px 15px;
	}
	/* News */
	.news-wrap .news-content{
		padding: 7px;
	}
	.news-wrap .news-content h5, .news-wrap .news-content h6 {
		font-size: 11px;
		margin: 5px 0;
		line-height:15px;
	}
	.news-wrap .news-content span{
		font-size: 8px;
	}
	/* Timeline */
	.universh-timeline-img i{
		font-size: 14px;
	}
	/* Page Templates */
	.template-box .countdown-section{
		width: 100%;
		margin-bottom: 20px;
		margin-left: 0;
		margin-right: 0;
	}
	/* Back top top */
	.cd-top {
		right: 10px;
		bottom: 10px;
		height: 30px;
		width: 30px;
	}
	.cd-top::after{
		font-size: 17px;
		top: 2px;
	}
	/* Counter Widget */
	.count-widget.inline {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		border-right: medium none;
		margin: 0 5px 10px 0;
		padding: 0 0 10px;
	}
	.widget .counter {
		font-size: 24px;
	}
	/* Footer */
	.gallery-widget li {
		width: 25%;
	}
	.footer-copyright .logo{
		width: 130px;
	}
}

/* Max Width 479px */
@media (max-width: 479px) {
	body {
		font-size: 13px;
	}
	/* Rev Button */
	.rev_slider .btn {
		margin-top: 0px;
		padding: 4px 12px;
		font-size: 8px;
	}
	/* Owl Slider */
	.hero .owl-carousel .item .slider-content h3 {
		font-size: 17px;
		font-weight: 500;
		margin-bottom: 0;
	}
	.owl-controls .owl-nav > div {
		height: 28px;
		line-height: 28px;
		width: 28px;
	}
	.owl-carousel .owl-controls .owl-nav .owl-prev::after, .owl-carousel .owl-controls .owl-nav .owl-next::after {
		font-size: 14px;
	}
	/* Countdown */
	.countdown-section{
		width: 100%;
	}
	/* Slider Below Section */
	.slider-below-wrap {
		padding: 20px;
	}
	/* Course */
	.course-wrapper .teacher-wrap h5{
		font-size: 18px;
	}
	/* Pricing */
	.pricing-wrap{
		padding: 30px 0;
	}
	.pricing-title > h4 {
		font-size: 26px;
	}
	.pricing-icon img{
		height: 80px;
		width: 80px;
	}
	.pricing-body > li {
		font-size: 18px;
		padding: 10px 20px;
	}
	li > .duration {
		font-size: 11px;
		margin-top: 6px;
	}
	.pricing-footer {
		padding: 30px 15px 0;
	}
	.pricing-footer .btn {
		height: auto;
		font-size: 13px;
		line-height: 22px;
	}
	/* Blog */
	.blog-meta li{
		font-size: 9px;
	}
	.blog-meta li i{
		height: 30px;
		width: 30px;
		line-height: 30px;
		font-size: 11px;
	}
	.post-type {
		height: 30px;
		width: 30px;
		bottom: -30px;
		line-height: 30px;
		font-size: 11px;
	}
	/* Tab */
	.nav-tabs > li{
		width: 100%;
	}
	.nav-tabs > li > a{
		display: block;
		border: none;
		margin-bottom: 5px;
	}
	.nav-tabs > li > a:hover, .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
		box-shadow: none; -webkit-box-shadow: none; -moz-box-shadow: none; -ms-box-shadow: none; -o-box-shadow: none;
		border: none;
	}
	/* Column Equal height */
	.col-eq-height.pad-60{
		padding: 30px;
	}
	/* Gallery */
	.gallery-detail .btn {
		font-size: 14px;
		font-weight: bold;
		padding: 8px;
	}
	.gallery-accordion .panel-body .gallery-detail p{
		display: none;
	}
	/* Event */
	.event-details h4{
		font-size: 22px;
	}
	/* Testimonial */
	.quote-footer .name{
		font-size: 16px;
	}
	/* Shop */
	.shop-title-wrap{
		display: block;
	}
	.shop-btns {
		float: none;
		margin-top: 10px;
	}


}
